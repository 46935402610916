/**
 * Mobile menu
 */
$('.page-header__mobile-btn').click(function () {
	$(this).toggleClass("active");
	$('.primary-nav').animate({
		height: "toggle",
		opacity: "toggle"
	}, "slow");
	return false;
});



/**
 * Form validation
 */
$(document).ready(function(){ 
	if (!!$.prototype.validate) {
		$("#contactForm").validate();
	}
});




/**
 * Testimonial slider
 */
import Swiper, { Autoplay, Pagination } from 'swiper';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';

jQuery(document).ready(function($) {

	if ( typeof Swiper !== "undefined" ){

		new Swiper('.swiper-testimonials', {
            modules: [Autoplay, Pagination],
			slidesPerView: "1", 
            loop: true,
            autoplay: {
                delay: 10000,
            },
            pagination: {
                el: '.swiper-pagination',
                clickable: true,
            },
		});

	}

});



/**
 * Animation
 */

// var waypoints = [];
// $('.fade').addClass("hidden").waypoint(function() { $(this.element).addClass("visible animated fadeIn"); }, { offset: '75%' });
